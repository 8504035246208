/*eslint-disable */
// import customFormBuildModel from '../../Model/customFormBuild'
import request from "../../Utils/curl";
import Utility from "../../Utils/utility";

let customFormBuilds = {
  /**
   * customFormBuildList
   */
  async customFormBuildList(context, whereFilter = null) {
    try {
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();
      if (whereFilter) {
        post_data.append("filter", JSON.stringify(whereFilter));
      }

      return await request.curl(context, "custom_form_build_list", post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at customFormBuildList() and Exception:", err.message);
    } 
    finally {
      Utility.showLoader = false;
    }
  },

  /**
   * customFormBuildView
   */
  async customFormBuildView(context, customFormBuildId) {
    try {
      let post_data = new FormData();
      post_data.append("cfb_id", customFormBuildId);
      return await request.curl(context, "custom_form_build_view", post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at customFormBuildView() and Exception:", err.message);
    }
  },

  /**
   * customFormBuildAdd
   */
  async customFormBuildAdd(context, customFormBuildObj) {
    try {
      let post_data = new FormData();

      for (let key in customFormBuildObj) {
        post_data.append(key, customFormBuildObj[key]);
      }

      return await request.curl(context, "custom_form_build_add", post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at customFormBuildAdd() and Exception:", err.message);
    }
  },

  /**
   * customFormBuildEdit
   */
  async customFormBuildEdit(context, customFormBuildObj) {
    try {
      let post_data = new FormData();

      for (let key in customFormBuildObj) {
        post_data.append(key, customFormBuildObj[key]);
      }

      return await request.curl(context, "custom_form_build_edit", post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at customFormBuildEdit() and Exception:", err.message);
    }
  },

  /**
   * customFormBuildDelete
   */
  async customFormBuildDelete(context, customFormBuildId) {
    try {
      let post_data = new FormData();

      post_data.append("cfb_id", customFormBuildId);

      return await request.curl(context, "custom_form_build_delete", post_data)
      .then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.log("Exception occurred at customFormBuildDelete() and Exception:", err.message);
    }
  },

  /**
   * customFormBuildGet
   */
  async customFormBuildGet(context, customFormBuildId) {
    try {
      let post_data = new FormData();

      post_data.append("cfb_id", customFormBuildId);

      return await request.curl(context, "custom_form_build_form_get", post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.log("Exception occurred at customFormBuildGet() and Exception:", err.message);
    }
  },

  /**
   * customFormBuildSubmitForm
   */
  async customFormBuildSubmitForm(context, customFormSubmitData, userToken) {
    let apiEndPoint = "custom_form_form_submit"
    if (userToken) {
      apiEndPoint = "custom_form_form_submit_private"
    }

    try {
      let post_data = new FormData();
      for (let key in customFormSubmitData) {
        if (customFormSubmitData[key] || typeof customFormSubmitData[key] === 'number') {
          post_data.append(key, customFormSubmitData[key])
        }
      }

      return await request.curl(context, apiEndPoint, post_data)
      .then(async (response) => {
        return response;
      });
    } 
    catch (err) {
      console.log("Exception occurred at customFormBuildSubmitForm() and Exception:", err.message);
    }
  },
};

export { customFormBuilds };
