/*eslint-disable */
<template>
  <b-container fluid>
    <b-row class="mt-0">
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
              <img src="../../assets_gide/img/logo/GideWhiteNRed.jpeg" class="img-fluid navBarLogo mt-3" alt="logo"/>
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="lead_add_form p-o" v-if="operatingsystem == 'web'">
        <iq-card class="p-4">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="customFormDetail.form_banner" style="width: 100%" /><!-- Event Image -->

            <!-- Event Description -->
            <div class="col-12 mt-4">
              <h4>{{customFormDetail.form_title}}</h4><br/>
              <span id="contactUsV1EventDescId" v-html="customFormDetail.form_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>
      <b-col md="4" lg="4" class="lead_add_form p-0">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img v-if="operatingsystem != 'web'" :src="customFormDetail.activity_banner" style="width: 100%"/>
            <h4 class="card-title mt-1 ml-3">
              {{ cvCardTitle }}&nbsp;&nbsp;{{ customFormDetail.form_title }}
            </h4>
            <!-- SignIn Link -->
            <div class="sign-info col-12" v-if="!showSessions && !customFormDetail.activity_is_paid">
              <span class="dark-color d-block line-height-2">Already Have An Account ?
                <router-link :to="{ name: 'auth.login' }"><span style="font-size: 18px">Sign In</span></router-link></span>
              <hr />
            </div>
            <!-- SignIn Link -->
          </template>
          <template v-slot:body>
            <ValidationObserver ref="pbaForm">
              <form action="#">
                <div class="form-row p-3">
                  <!-- Scroll Bar CSS -->
                  <div v-bind:class=" operatingsystem == 'web' ? 'gide_scroll_box row m-0' : 'gide_box row m-0'">
                    <div class="col-md-12 mb-3" v-for="(field, index) of customFormDetail.custom_form_fields" :key="index">
                      <!-- <p>{{JSON.stringify(field )}}</p> -->
                      <!-- Input Box -->
                      <div v-if="field.form_field_type == 'INPUT'">
                        <label :for="field.form_field_label">{{field.form_field_label}}</label>
                        <label class="primary-color font-size-16">*</label>
                        <ValidationProvider :name="field.form_field_label" :rules="`required${field.form_field_variable.includes('email') ? '|email': ''}`" v-slot="{ errors }">
                          <input v-model="vmCustomFormData[field.form_field_variable]" :type="(field.form_field_variable.includes('password') ? 'password': 'text')" class="form-control" id="field.form_field_label" required/>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div><!-- Input Box -->

                      <!-- Dropdowns -->
                      <div v-if="field.form_field_type == 'DROPDOWN'">
                        <p> {{ field.form_field_label}}<label class="primary-color font-size-16">*</label>
                        </p>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            :options="JSON.parse(field.form_dropdown_structure)"
                            track-by="name"
                            label="name"
                            id="validation_parentType"
                            v-model="vmCustomFormData[field.form_field_variable]"
                            required
                          >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div>
                    </div>

                    <!-- Check Box -->
                    <div class="w-100 col-12" v-if="!showSessions">
                      <input type="checkbox" id="checkbox" v-model="tnc"/>&nbsp;&nbsp;<span for="checkbox">I have read and accept the
                      <a href="/content/Tnc" target="_blank">Terms of Use</a></span>
                    </div>
                    <!-- Check Box -->
                    <br />

                    <!-- Submit Button -->
                    <div class="form-group float-right w-100 col-12">
                      <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                        <b-spinner label="Spinning"></b-spinner>
                      </button>
                      <button type="button" class="btn btn-primary" style="float: right" v-if="!showSessions" @click="createLead()">
                        {{ cvSubmitBtn }}
                      </button>
                    </div>
                    <!-- Submit Button -->

                    <!-- Social Icons -->
                    <div class="sign-info col-12" v-if="!showSessions && !customFormDetail.activity_is_paid">
                      <span class="dark-color d-block line-height-2">Already Have An Account ?
                        <router-link :to="{ name: 'auth.login' }">Sign In</router-link></span>
                      <ul class="iq-social-media mt-4">
                        <li>
                          <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                        </li>
                        <li>
                          <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                        </li>
                      </ul>
                    </div>
                    <!-- Social Icons -->
                  </div>
                  <!-- Scroll Bar CSS -->
                </div>
                <!-- form-row m-0 -->
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>

    <AlertBox
      :key="showUpgradeAlertBox"
      v-if="showUpgradeAlertBox"
      :propAlertTitle="alertTitle"
      :propAlertDesc="alertDesc"
      :propOkBtnText="okText"
      :propCancelBtnText="cancelText"
      @emitCloseAlertModel="showUpgradeAlertBox = false"
      @emitConfirmAlertModel="goToEventPlanList()"
    />
    <AlertBox :key="showFormSubmissionStatus" v-if="showFormSubmissionStatus" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showFormSubmissionStatus = false" @emitConfirmAlertModel="redirectToAuth()"/>

    <!-- Payment Modal -->
    <template v-if="showPaymentModal && customFormId">
      <b-modal v-model="showPaymentModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true" @hide="emitPaymentStatus(false)">
        <Paygw :propModuleType="modName" :propModuleName="form_title" :propModuleObjId="customFormId" :propSrc="SRC" @emitPaymentStatus="emitPaymentStatus(true)" :propEventMktSrc="source" :propRedirect="'/auth/confirmation?code=PAYMENT_DONE'" :propUserId="userId" :propUserRole="userRole" :propUserEmail="vmCustomFormData[customFormDetail.form_email_field_variable]"/>
          <template #modal-footer="">
            <b-button @click="emitPaymentStatus(false)" size="sm" class="pull-left">Close</b-button>
          </template>
      </b-modal>
    </template><!-- Payment Modal -->
  </b-container>
</template>

<script>
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"
import Paygw from "../../views/Gide/Paygw/Paygw.vue"
import AlertBox from "../../components/AlertBox.vue"
import { customFormBuilds } from "../../FackApi/api/customFormBuild.js"
import { socialvue } from "../../config/pluginInit"
import ApiResponse from "../../Utils/apiResponse"
import UserRolesJson from "../../FackApi/json/UserRoles.json"

export default {
  name: "CustomActivityForm",
  components: {
    ValidationProvider,
    Multiselect,
    AlertBox,
    Paygw
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userId: null,
      userRole: "USERROLE11114",
      customFormId: null,
      customFormDetail: {},
      operatingsystem: window.__DEVICE_OS__,
      cvSubmitBtn: "Submit",
      showSessions: false,
      cvLoadingStatus: false,
      tnc: false,
      vmCustomFormData: {},
      toastVariant: "default",
      toastTitle: "Registration",
      showToast: false,
      toastMsg: null,
      showUpgradeAlertBox: false,
      showPaymentModal: false,
      cvCardTitle: "Register for",
      customFormDropdownList: [],
      SRC: "customActivityForm",
      PRE_VERFIFIED_USER: false,
      modName: "custom_form",
      form_title: "",
      form_banner: "",
      form_desc: "",
      source: "web",
      userRolesJson: {},
      alertTitle: "Your Response is captured!",
      alertDesc: "Dear user, you response has been successfully captured, kindly proceed with creating your profile on GIDE.AI",
      okText: "Proceed",
      cancelText: "Not Now",
      showFormSubmissionStatus: false
    }
  },
  computed: {
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    },
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    userToken () {
      return this.$store.getters["User/getToken"]
    }
  },
  async mounted () {
    socialvue.index()
    this.userRolesJson = UserRolesJson
    await this.getCustomFormData()
  },
  methods: {
    async getCustomFormData () {
      try {
        this.customFormId = this.$route.params.activityId
        const customFormDetail = await customFormBuilds.customFormBuildGet(this, this.customFormId)
        if (customFormDetail.resp_status) {
          this.customFormDetail = customFormDetail.resp_data.data
        }

        this.customFormDropdownList = this.customFormDetail.dropdown_list

        // Check whether to use the info preset in the module attached to form of use the form title. module_name = event, app, club etc
        if (this.customFormDetail.module_name && this.customFormDetail.module_obj_id) {
          let moduleData = this.customFormDetail.module_data

          this.customFormDetail.form_title = moduleData.module_title
          this.customFormDetail.form_banner = moduleData.module_image
          this.customFormDetail.form_desc = moduleData.module_desc
        }
      }
      catch (err) {
        console.error("Exception in getCustomFormData and err: ", err)
      }
    },
    async createLead () {
      try {
        if (!this.tnc) {
          this.toastMsg = "You have not accepted the terms & conditions"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.vmCustomFormData.cfb_id = this.$route.params.activityId
        if (this.customFormDropdownList) {
          for (let drpdn of this.customFormDropdownList) {
            this.vmCustomFormData[drpdn.form_field_variable] = this.vmCustomFormData[drpdn.form_field_variable].name
          }
        }

        // Capture the interest of the user for the campaign in a dedicated database
        const customFormSubmissionResp = await customFormBuilds.customFormBuildSubmitForm(this, this.vmCustomFormData, this.userToken)
        ApiResponse.responseMessageDisplay(this, customFormSubmissionResp)
        if (customFormSubmissionResp.resp_status && customFormSubmissionResp.resp_data) {
          const respUserData = customFormSubmissionResp.resp_data

          this.userId = respUserData.user_id
          this.userRole = respUserData.user_role

          let user = {
            user_name: respUserData.user_name,
            user_email: respUserData.user_email,
            user_id: respUserData.user_id,
            user_mobile: respUserData.user_mobile,
            user_role: respUserData.user_role,
            type: this.userRolesJson[respUserData.user_role]
          }

          await this.$store.dispatch("User/setUserProfileAction", user)
          this.showPaymentModal = true
        }

        if (!this.customFormDetail.form_subs_id) {
          this.showFormSubmissionStatus = true
        }
      }
      catch (err) {
        console.error("Exception in createLead and err: ", err)
      }
    },
    /**
     * redirectToAuth
     */
    redirectToAuth () {
      this.$router.push("/login")
    },
    /**
     * emitPaymentStatus
    */
    async emitPaymentStatus (event) {
      this.customFormId = null
      this.showPaymentModal = false
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import url("../../assets/css/custom.css");
@import url("../../assets/css/PriceSlider.css");
.student_parent_gide {
  .text-danger {
    color: #dc3545 !important;
  }
  .sign-info {
    margin-top: 0px;
    border: 0px;
    padding-top: 30px;
  }
  .student_parent_subhead {
    color: var(--iq-body-text);
    font-size: 14px;
  }
  .form-control {
    height: 45px;
    line-height: 45px;
    background: transparent;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    color: var(--iq-secondary);
    border-radius: 10px;
  }
  .lead_add_form {
    .table thead th {
      border-bottom: 0px;
    }
    .card-title {
      color: var(--iq-title-text);
    }
    label {
      color: var(--iq-body-text);
    }
    .iq-card {
      margin: 0px;
      border: none;
      box-shadow: none;
      .iq-card-header {
        padding: 0px;
        border: 0px;
      }
      .iq-card-body {
        padding: 0px;
      }
    }
  }
}
@media screen and (min-width: 576px) {
  .gide_scroll_box {
    overflow: auto;
    height: calc(100vh);
    max-height: 550px;
  }
  .gide_box {
    overflow: auto;
    height: calc(100vh - 320px);
    max-height: 550px;
  }
}
textarea {
  min-height: 150px;
  line-height: 27px !important;
}
.event_data {
  .gide_scroll_box {
    max-height: 800px;
  }
}
</style>
